import React, { useState } from "react";
import "../css/our-gallery.css";
import GalleryImage1 from "../assets/gallery/1.png";
import GalleryImage2 from "../assets/gallery/2.png";
import GalleryImage3 from "../assets/gallery/3.png";
import GalleryImage4 from "../assets/gallery/4.png";
import GalleryImage5 from "../assets/gallery/5.png";
import GalleryImage6 from "../assets/gallery/6.png";
import GalleryImage7 from "../assets/gallery/7.png";
import GalleryImage8 from "../assets/gallery/8.png";
import GalleryImage9 from "../assets/gallery/9.png";
import GalleryImage10 from "../assets/gallery/10.png";
import GalleryImage11 from "../assets/gallery/11.png";
import GalleryImage12 from "../assets/gallery/12.png";
import GalleryImage13 from "../assets/gallery/13.png";
import GalleryImage14 from "../assets/gallery/14.png";
import GalleryImage15 from "../assets/gallery/15.png";
import GalleryImage16 from "../assets/gallery/16.png";
import GalleryImage17 from "../assets/gallery/17.png";
import GalleryImage18 from "../assets/gallery/18.png";
import GalleryImage19 from "../assets/gallery/19.png";
import GalleryImage21 from "../assets/gallery/21.png";
import GalleryImage22 from "../assets/gallery/22.png";
import GalleryImage23 from "../assets/gallery/23.png";
import GalleryImage24 from "../assets/gallery/24.png";
import GalleryImage25 from "../assets/gallery/25.png";
import GalleryImage26 from "../assets/gallery/26.png";
import GalleryImage27 from "../assets/gallery/27.png";
import GalleryImage28 from "../assets/gallery/28.png";
import GalleryImage29 from "../assets/gallery/29.png";
import GalleryImage30 from "../assets/gallery/30.png";
import GalleryImage31 from "../assets/gallery/31.png";
import GalleryImage32 from "../assets/gallery/32.png";
import GalleryImage33 from "../assets/gallery/33.png";
import GalleryImage34 from "../assets/gallery/34.png";
import GalleryImage35 from "../assets/gallery/35.png";
import GalleryImage36 from "../assets/gallery/36.png";
import GalleryImage37 from "../assets/gallery/37.png";
import GalleryImage38 from "../assets/gallery/38.png";
import GalleryImage39 from "../assets/gallery/39.png";
import GalleryImage40 from "../assets/gallery/40.png";
import GalleryImage41 from "../assets/gallery/41.png";
import GalleryImage42 from "../assets/gallery/42.png";
import GalleryImage43 from "../assets/gallery/43.png";
import GalleryImage44 from "../assets/gallery/44.png";
import GalleryImage45 from "../assets/gallery/45.png";
import GalleryImage46 from "../assets/gallery/46.png";
import GalleryImage47 from "../assets/gallery/47.png";
import GalleryImage48 from "../assets/gallery/48.png";
import GalleryImage49 from "../assets/gallery/49.png";
import GalleryImage50 from "../assets/gallery/50.png";
import GalleryImage51 from "../assets/gallery/51.png";
import GalleryImage52 from "../assets/gallery/52.png";
import GalleryImage53 from "../assets/gallery/53.png";
import GalleryImage54 from "../assets/gallery/54.png";

const imageList = [
  GalleryImage1,
  GalleryImage2,
  GalleryImage3,
  GalleryImage4,
  GalleryImage5,
  GalleryImage6,
  GalleryImage7,
  GalleryImage8,
  GalleryImage9,
  GalleryImage10,
  GalleryImage11,
  GalleryImage12,
  GalleryImage13,
  GalleryImage14,
  GalleryImage15,
  GalleryImage16,
  GalleryImage17,
  GalleryImage18,
  GalleryImage19,
  GalleryImage21,
  GalleryImage22,
  GalleryImage23,
  GalleryImage24,
  GalleryImage25,
  GalleryImage26,
  GalleryImage27,
  GalleryImage28,
  GalleryImage29,
  GalleryImage30,
  GalleryImage31,
  GalleryImage32,
  GalleryImage33,
  // GalleryImage34,
  GalleryImage35,
  GalleryImage36,
  GalleryImage37,
  GalleryImage38,
  GalleryImage39,
  GalleryImage40,
  GalleryImage41,
  GalleryImage42,
  GalleryImage43,
  GalleryImage44,
  GalleryImage45,
  GalleryImage46,
  GalleryImage47,
  GalleryImage48,
  GalleryImage49,
  GalleryImage50,
  GalleryImage51,
  GalleryImage52,
  GalleryImage53,
  GalleryImage54,
];

const OurGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null); // To store the clicked image

  const openImage = (image) => {
    setSelectedImage(image); // Sets the clicked image to display in the popup
  };

  const closeImage = () => {
    setSelectedImage(null); // Closes the popup by resetting the selected image
  };

  return (
    <div className="our-gallery-section">
      <h2 className="swipe">The Rajmahal Gallery</h2>
      <h4 className="swipe">Explore The Rajmahal</h4>
      <div className="our-gallery-wrapper">
        {imageList.map((image, index) => (
          <div
            className="our-gallery-image swipe"
            key={index}
            onClick={() => openImage(image)}
          >
            <img src={image} alt={`Gallery image ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Popup section */}
      {selectedImage && (
        <div className="popup-image">
          <span className="close-btn" onClick={closeImage}>
            <i className="fa-solid fa-xmark"></i>
          </span>
          <img src={selectedImage} alt="Popup" />
        </div>
      )}
    </div>
  );
};

export default OurGallery;
