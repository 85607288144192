import React from "react";
import { useNavigate } from "react-router-dom";
import Rajmahal from "../assets/hotel-image3.png";
import "../css/about-homepage.css";

const AboutHomepage = () => {
  const navigate = useNavigate();
  return (
    <div className="about-homepage-section">
      <h2>The Rajmahal</h2>
      <br />
      <div className="about-homepage-container">
        <div className="about-homepage-container-left swipe-left">
          <img src={Rajmahal} alt="Rajmahal Photo" />
        </div>
        <div className="about-homepage-container-right swipe-right">
          <p>
            The family of Rajmahal Devgadh Baria originates from the Khichi
            Chauhan dynasty, descendants of Prithviraj Chauhan. Devgadh Baria
            town was establish ed in1524 by Maharawal Dungar Singh Ji grandson
            of Maharawal Patai Rawal, after escaping the conquest by Mohammad
            Begada in Pavagadh. Rest of the geneology can be followed on
            wikipedia.
          </p>
          <br />
          <button
            onClick={() => {
              navigate("/about-heritage");
            }}
          >
            Know More
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutHomepage;
