import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import TopBanner from '../components/top-banner';
import Footer from '../components/footer';
import Payment from '../components/payment';
import SocialMediaFixed from '../components/socialmedia-fixed';

const PaymentPage = () => {
    return (
        <>
            <Helmet>
                <title>Payment - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Secure your booking with Rajmahal Devgadh Baria by making easy and fast payments. Our payment options are safe and convenient for your stay." />
                <meta name="keywords" content="payment, Rajmahal Devgadh Baria, secure payment, online payment, hotel booking" />
                <link rel="canonical" href="https://rajmahaldevgadhbaria.com/payment" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Payment - Rajmahal Devgadh Baria",
                        "telephone": "+91 9971834111",
                        "url": "https://rajmahaldevgadhbaria.com/payment"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <Payment />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default PaymentPage;
