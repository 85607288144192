import React from "react";
import "../css/nearby-attraction.css";
import nearbyAttractions from "../data/nearby-attraction-data";
import NearbyAttractionCard from "./nearby-attraction-card";

const NearbyAttraction = () => {
  return (
    <div className="nearby-attraction-section">
      <h1>Nearby Attractions</h1>
      <br />
      <div className="nearby-attraction-container">
        {nearbyAttractions.map((attraction, index) => (
          <NearbyAttractionCard
            key={index}
            imageSrc={attraction.imageSrc}
            title={attraction.title}
            description={attraction.description}
            buttonText={attraction.buttonText}
          />
        ))}
      </div>
    </div>
  );
};

export default NearbyAttraction;
