import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import ContactUs from '../components/contact-us';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Contact us for hotel room bookings, inquiries, and more at Rajmahal Devgadh Baria. Get in touch with our team for personalized assistance and booking support." />
                <meta name="keywords" content="contact Rajmahal Devgadh Baria, hotel room booking inquiries, Devgadh Baria contact details, Rajmahal customer service, hotel booking support, Rajmahal contact page, get in touch with Rajmahal, hotel reservations support" />
                <link rel="canonical" href="https://rajmahaldevgadhbaria.com/contact" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Contact Us - Rajmahal Devgadh Baria - +91 9971834111",
                        "telephone": "+91 9971834111",
                        "url": "https://rajmahaldevgadhbaria.com/contact"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <ContactUs />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default ContactPage;
