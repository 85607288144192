// data/nearby-attraction-data.js
import RatanMahal from "../assets/ratanmahal.jpg";
import UdhalMahuda from "../assets/udhal mahuda.jpg";
import Sagtala from "../assets/sagtala.jpg";
import DudhanaFarmHouse from "../assets/dudhana farm house.png";
import AvantiPrivateResidence from "../assets/avanti private residence.png";

const nearbyAttractions = [
    {
        imageSrc: RatanMahal,
        title: "Ratan Mahal Sanctuary",
        description:
            "Ratanmahal Wildlife Sanctuary in Dahod, Gujarat, is home to the state's largest sloth bear population, set in rugged, hill-station-like forests. Established in 1982, it offers a unique wildlife experience near the Gujarat-Madhya Pradesh border.",
    },
    {
        imageSrc: UdhalMahuda,
        title: "Udhal Mahuda",
        description:
            "Udhal Mahuda, part of the Ratanmahal Sloth Bear Sanctuary near Devgadh Baria in Gujarat, offers stunning views, including the Mira Waterfall, along with accommodations in tents and cottages for an immersive nature experience.",
    },
    {
        imageSrc: Sagtala,
        title: "Sagtala",
        description:
            "Sagtala is a village in Devgad Bariya Taluka, Dahod District, Gujarat, located 55 km west of Dahod and surrounded by villages like Fangiya, Dabhva, and Navi Bedi.It lies near Chhota Udepur, Dhanpur, Ghoghamba, and Jambughoda Talukas.",
    },
    {
        imageSrc: DudhanaFarmHouse,
        title: "Dudhana Farm House",
        description:
            "Dudhana is a serene and quaint cottage, beautifully surrounded by lush amla and lemon trees, offering a peaceful retreat in nature. Owned by Rajmahal Baria, it provides a perfect escape to unwind and experience the tranquility of countryside.",
    },
    {
        imageSrc: AvantiPrivateResidence,
        title: "Avanti - Private Residence",
        description:
            "Avanti is a 20-acre lush forest retreat, originally a getaway spot for the Maharani's of Devgadh Baria. It was later converted into the royal residence, where the royal family lived until March 2013, before moving to Rajmahal.",
    },

];

export default nearbyAttractions;
