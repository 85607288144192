import React from "react";
import { useNavigate } from "react-router-dom";

const RoomCard = ({ title, image, services }) => {
  const navigate = useNavigate();
  return (
    <div className="room-card swipe">
      <div className="room-card-image">
        <img src={image} alt={`${title} Room Image`} />
      </div>
      <div className="room-card-contents">
        <div className="room-card-title">
          <h2>{title}</h2>
        </div>
        <div className="room-card-services">
          {services.map((service, index) => (
            <span key={index}>
              <i className={`fa-solid ${service.icon}`}></i>
              <h4>{service.name}</h4>
            </span>
          ))}
        </div>
        <div className="room-card-buttons">
          <button>View More</button>
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
