import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import Services from '../components/services';
import SocialMediaFixed from '../components/socialmedia-fixed';

const ServicesPage = () => {
    return (
        <>
            <Helmet>
                <title>Services - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Explore the premium services offered at Rajmahal Devgadh Baria. From luxurious hotel rooms to personalized guest services, we provide a royal experience for all our visitors." />
                <meta name="keywords" content="Rajmahal Devgadh Baria services, luxury hotel services, hotel amenities, Rajmahal guest services, Devgadh Baria room service, royal hotel experience, premium services Rajmahal, hotel booking services, personalized services Devgadh Baria" />
                <link rel="canonical" href="https://rajmahaldevgadhbaria.com/services" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Services - Rajmahal Devgadh Baria",
                        "telephone": "+91 9971834111",
                        "url": "https://rajmahaldevgadhbaria.com/services"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <Services />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default ServicesPage;
