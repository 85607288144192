import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import HomePage from './pages/homepage';
import AboutPage from './pages/aboutpage';
import ContactPage from './pages/contactpage';
import GalleryPage from './pages/gallerypage';
import RoomsPage from './pages/roomspage';
import PaymentPage from './pages/paymentpage';
import ServicesPage from './pages/servicespage';
import PlacesToVisitPage from './pages/placestovisitpage';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about-heritage' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/gallery' element={<GalleryPage />} />
          <Route path='/rooms' element={<RoomsPage />} />
          <Route path='/payment' element={<PaymentPage />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/places-to-visit' element={<PlacesToVisitPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
