import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import AboutHeritage from '../components/about-heritage';

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title>About Rajmahal Devgadh Baria - Story & Vision</title>
                <meta name="description" content="Explore Rajmahal in Devgadh Baria, a historical palace with rich architecture and royal heritage. Discover the best attractions, travel tips, and history of Rajmahal, located in Gujarat." />
                <meta name="keywords" content="Rajmahal Devgadh Baria, Rajmahal palace, Devgadh Baria tourism, Rajmahal Gujarat, Rajmahal architecture, Devgadh Baria attractions, Gujarat historical sites, Devgadh Baria history, travel to Rajmahal Devgadh Baria, Rajmahal royal heritage, best hotel Devgadh Baria, hotel booking Rajmahal Devgadh Baria, Rajmahal hotel rooms, luxury hotel booking, Devgadh Baria accommodations, book room in Rajmahal, hotel reservations in Gujarat, Rajmahal palace stay, royal hotel experience, Devgadh Baria hotel services" />
                <link rel="canonical" href="https://rajmahaldevgadhbaria.com/about-heritage" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "About Rajmahal Devgadh Baria - Story & Vision",
                        "telephone": "+91 9971834111",
                        "url": "https://rajmahaldevgadhbaria.com/about-heritage"
                    })}
                </script>
            </Helmet>
            <TopBanner />
            <Navbar />
            <AboutHeritage />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default AboutPage;
