import React from "react";

const NearbyAttractionCard = ({ imageSrc, title, description }) => {
  return (
    <div className="nearby-attraction-card swipe">
      <div className="image-section">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="content-section">
        <h2>{title}</h2>
        <p>{description}</p>
        <button>Know More</button>
      </div>
    </div>
  );
};

export default NearbyAttractionCard;
