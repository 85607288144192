import React from "react";
import "../css/services.css";
import Sqaush from "../assets/icons/tennis-court.png";
import Hiking from "../assets/icons/mountain.png";
import Expedition from "../assets/icons/route.png";
import Barbeque from "../assets/icons/grill.png";
import Picnic from "../assets/icons/picnic.png";

const Services = () => {
  return (
    <section className="services-section">
      <h1 className="swipe">The Rajmahal Facilities</h1>
      <div className="services-container">
        <h2 className="swipe">OUR SERVICES</h2>
        <br />
        <div className="services-boxes">
          <div className="service-box swipe">
            <i class="fa-solid fa-van-shuttle"></i>
            <span>Pick Up & Drop</span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-house"></i>
            <span>Room Service </span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-car"></i>
            <span>Parking Space</span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-utensils"></i>
            <span>Royal Lunch</span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-wifi"></i>
            <span>Fibre Internet</span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-burger"></i>
            <span>Breakfast</span>
          </div>
        </div>
      </div>
      <div className="services-container">
        <h2>Activities</h2>
        <p>Enjoy comfort and calm in the city</p>
        <br />
        <div className="services-boxes2">
          <div className="service-box">
            <i class="fa-solid fa-futbol"></i>
            <span>Football field</span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-table-tennis-paddle-ball"></i>
            <span>Tennis court</span>
          </div>
          <div className="service-box swipe">
            <img src={Sqaush} alt="" />
            <span>Squash court</span>
          </div>
          <div className="service-box swipe">
            <i class="fa-solid fa-person-biking"></i>
            <span>Cycling</span>
          </div>
          <div className="service-box swipe">
            <img src={Expedition} alt="" />
            <span>Expedition</span>
          </div>
          <div className="service-box swipe">
            <img src={Hiking} alt="" />
            <span>Hiking</span>
          </div>
          <div className="service-box swipe">
            <img src={Barbeque} alt="" />
            <span>Barbeque</span>
          </div>
          <div className="service-box swipe">
            <img src={Picnic} alt="" />
            <span>Picnics</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
