import React from "react";
import "../css/rooms.css";
import roomsData from "../data/roomsData"; // Import the room data
import RoomCard from "./roomcard"; // Ensure RoomCard component is correctly imported

const Rooms = () => {
  return (
    <div className="rooms-section">
      <h1>The Rajmahal Rooms</h1>
      <br />
      <div className="rooms-container">
        {roomsData.map((room, index) => (
          <RoomCard
            key={index}
            title={room.title}
            image={room.image}
            services={room.services}
          />
        ))}
      </div>
    </div>
  );
};

export default Rooms;
