import React from "react";
import "../css/about-heritage.css";
import Heritage1 from "../assets/about-heritage/1.png";
import Heritage2 from "../assets/about-heritage/2.png";
import Heritage3 from "../assets/about-heritage/3.png";
import Heritage4 from "../assets/about-heritage/4.png";
import Heritage5 from "../assets/about-heritage/5.png";

const AboutHeritage = () => {
  return (
    <section className="about-heritage-section">
      <div className="about-container">
        <h1>About Rajmahal</h1>
        <p className="about-description">
          Welcome to Rajmahal Devgadh Baria, a historic blend of luxury,
          culture, and royal heritage.
        </p>

        <div className="history-section">
          <br />
          <h2>History of Rajmahal Devgadh Baria </h2>
          <br />
          <div className="image-content">
            <img
              src={Heritage1}
              alt="Rajmahal"
              className="history-image"
              loading="eager"
            />
            <div className="heritage-content">
              <p>
                The family of Rajmahal Devgadh Baria originates from the Khichi
                Chauhan dynasty, descendants of the legendary Prithviraj
                Chauhan. Devgadh Baria town was established in 1524 by Maharawal
                Dungar Singh Ji, the grandson of Maharawal Patai Rawal, after
                his escape from the conquest by Mohammad Begada in Pavagadh. The
                royal lineage is rooted in a long and rich history, and more
                details can be found in the genealogy on Wikipedia.
              </p>
              <br />
              <p>
                The palace, originally built in 1904, has been an emblem of
                royal grandeur, featuring four opulent suites, lush gardens,
                open spaces, and a serene lake. The palace also boasts a grand
                ballroom, built during the visit of Sir Frederick Hugh Sykes,
                the Governor of Bombay.
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="history-section">
          <br />
          <h2>The Royal Family of Rajmahal Devgadh Baria</h2>
          <br />
          <div className="image-content">
            <img
              src={Heritage4}
              alt="Rajmahal"
              className="history-image"
              loading="eager"
            />
            <div className="heritage-content">
              <p>
                On March 13, 2013, the present royal family moved back into The
                Rajmahal, after an extensive 9-year renovation project that
                restored the palace to its original glory. The palace stands
                today as a symbol of both the heritage and the modern vision of
                the royal family.
              </p>
              <br />
              <p>
                Princess Urvashi Devi, former Minister and MLA of the Gujarat
                State Government, has made significant contributions to the
                rural development of the town and neighboring areas of Devgadh
                Baria. She is also a passionate advocate for animal welfare and
                environmental preservation.
              </p>
              <br />
              <p>
                Maharaja Tushar Singh Ji, the 18th Maharawal of Devgadh Baria,
                is the current head of the royal family. He was also a former
                MLA in the Gujarat State Government. Maharaja Tushar Singh Ji
                resides in the palace with his wife, Maharani Ambika Kumari Ji,
                and their two sons: Yuvraj Arunoday Singh Ji and Maharaj Kumar
                Nirbhay Singh Ji.
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="history-section">
          <br />
          <h2>The Heritage of Rajmahal</h2>
          <br />
          <div className="image-content">
            <img
              src={Heritage5}
              alt="Heritage of Rajmahal"
              className="heritage-image"
            />
            <div className="heritage-content">
              <p>
                The Rajmahal is not just a monument but a living symbol of
                history. Its sprawling courtyards, regal halls, and intricate
                architecture reflect the opulence of its era. Every room, every
                corner, tells the story of a glorious past.
              </p>
              <br />
              <p>
                Its architecture blends Mughal and Rajput influences, showcasing
                vaulted arches, elegant columns, and stunning frescoes that
                narrate tales of royal grandeur. The Rajmahal remains a symbol
                of the artistic and cultural amalgamation that India has long
                been known for.
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="history-section">
          <br />
          <h2>Our Mission & Vision</h2>
          <br />
          <div className="image-content">
            <img
              src={Heritage2}
              alt="Heritage of Rajmahal"
              className="heritage-image"
            />
            <div className="heritage-content">
              <p>
                At Rajmahal, our mission is to preserve the rich cultural
                heritage and offer visitors an immersive experience into the
                royal world of ancient India. We aim to provide a luxurious and
                enriching experience, where guests can explore the history, art,
                and culture that have defined our region for centuries.
              </p>
              <br />
              <p>
                Our vision is to maintain the heritage, to restore its glory,
                and make the Rajmahal a place where history meets luxury. We
                seek to honor the past while offering a contemporary,
                world-class experience for our visitors.
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="history-section">
          <br />
          <h2>Contact Us</h2>
          <br />
          <p>
            For more details on visiting the Rajmahal, booking tours, or making
            inquiries, feel free to get in touch with us. We are here to assist
            you in every way possible and help you experience the royal
            lifestyle firsthand.
          </p>
          <br />
          <p>
            Email:{" "}
            <a href="mailto:booking@rchronline.com">booking@rchronline.com</a>
          </p>
          <p>
            Phone: <a href="tel:+919971834111">+91 9971834111</a>
          </p>
          <p>
            Phone: <a href="tel:+918743000607">+91 8743000607</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutHeritage;
