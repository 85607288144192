import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/hero-section.css";
import hotelImage from "../assets/hotel-image3.png"; // import your image

const HeroSection = () => {
  const navigate = useNavigate();
  const [hotelType, setHotelType] = useState("");
  const [location, setLocation] = useState("");
  const [isImageLoaded, setImageLoaded] = useState(false);

  const handleSearch = () => {
    if (location) {
      navigate(`/our-city/${location.toLowerCase()}`);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = hotelImage;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <>
      {/* {loading && (
        <div className="loader">
          <img src={Loader} alt="Loading..." fetchPriority="high" />
        </div>
      )} */}
      <div
        className="hero-section"
        style={{
          background: isImageLoaded
            ? `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 100%), url(${hotelImage}) no-repeat center center / cover`
            : "lightgray", // Optional: placeholder color while loading
        }}
      >
        <div className="overlay">
          <h2 className="swipe">
            Welcome <br /> Rajmahal Devgadh Baria
          </h2>

          <div className="find-hotels swipe"></div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
