// /data/roomsdata.js

import SunkenGardenSuite from "../assets/room/sunken-garden-suite.png";
import MaharaniSuite from "../assets/room/maharani-suite.png";
import MaharajaSuite from "../assets/room/maharaja-suite.png";
import RoyalSuite from "../assets/room/royal-suite.png";

const roomsData = [
    {
        title: "Sunken Garden Suite",
        image: SunkenGardenSuite,
        services: [
            { icon: "fa-user-group", name: "1-2 Persons" },
            { icon: "fa-wifi", name: "Free Wifi" },
            { icon: "fa-bed", name: "Extra Bed" },
            { icon: "fa-phone", name: "Telephone" },
            { icon: "fa-bed", name: "Living Room" },
            { icon: "fa-mug-hot", name: "Coffee Service" },
        ],
    },
    {
        title: "Maharani Suite",
        image: MaharaniSuite, // Use another image for this room
        services: [
            { icon: "fa-user-group", name: "1-2 Persons" },
            { icon: "fa-wifi", name: "Free Wifi" },
            { icon: "fa-bed", name: "Extra Bed" },
            { icon: "fa-phone", name: "Telephone" },
            { icon: "fa-bed", name: "Living Room" },
            { icon: "fa-mug-hot", name: "Coffee Service" },
        ],
    },
    {
        title: "Maharaja Suite",
        image: MaharajaSuite, // Use another image for this room
        services: [
            { icon: "fa-user-group", name: "1-2 Persons" },
            { icon: "fa-wifi", name: "Free Wifi" },
            { icon: "fa-bed", name: "Extra Bed" },
            { icon: "fa-phone", name: "Telephone" },
            { icon: "fa-bed", name: "Living Room" },
            { icon: "fa-mug-hot", name: "Coffee Service" },
        ],
    },
    {
        title: "Royal Suite",
        image: RoyalSuite, // Use another image for this room
        services: [
            { icon: "fa-user-group", name: "1-2 Persons" },
            { icon: "fa-wifi", name: "Free Wifi" },
            { icon: "fa-bed", name: "Extra Bed" },
            { icon: "fa-phone", name: "Telephone" },
            { icon: "fa-bed", name: "Living Room" },
            { icon: "fa-mug-hot", name: "Coffee Service" },
        ],
    },
    // Add more room data as needed
];

export default roomsData;
